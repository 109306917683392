import { Injectable, Injector } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { FunctionalErrorsType, StateResponse, StateResponseError } from "app/services/declaration-state.service";
import { DeclarationStatePipe } from './pipes/declaration-state.pipe';
import { take } from 'rxjs';
import { MainBroadcasterService } from './broadcaster.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    // private _destroy: Subject<void> = new Subject<void>();

    prevLength = 0;

    declarationState: DeclarationStatePipe;

    constructor(
        //  private declarationState: DeclarationStatePipe,
        private toastrService: ToastrService,
        private mainBroadcaster: MainBroadcasterService,
    ) {
        this.declarationState = new DeclarationStatePipe();

    }

    notify(res: StateResponse) {
        //On simple message
        console.log('interceptor response', res);
        const timeoutx = (res.subdomain === 'DAO' || res.subdomain === 'DEFK') ? 120000 : 60000;

        const toastrProps: Partial<IndividualConfig> = {};

        if ((res.internalState === 'OK' || res.internalState === 'CHECK') && !res.noWaitNotify) {
            toastrProps.timeOut = timeoutx;
            toastrProps.extendedTimeOut = timeoutx;
        }


        if (res.revert) {
            return;
        }

        if (!res.message) {
            console.log('res.message NOT FOUND!!');
            //this.toastrService.clear();
        }

        if (res.message) {
            if (!res.stateCode) {
                this.toastrService.info(res.message, 'Info', toastrProps);
            } else {
                //this. timeout is bound to the submit timeout in submitFormService
                if (
                    res.stateCode === 'Submitted' ||
                    res.stateCode === 'Under Amendment' ||
                    res.stateCode === 'Under Cancellation' ||
                    res.stateCode === 'Cancellation Submitted'
                ) {
                    this.toastrService.clear();
                }
                console.log('res.stateCode', res.stateCode);
                console.log('res.message', res.message);

                this.toastrService.info(res.message, 'Info', toastrProps);
            }
        } else if (res.data) {
            this.onFinalData(res);
            //On error
        } else if (res.error) {
            if (res.revert) {
                return;
            }
            if (Array.isArray(res.error)) {
                res.error.forEach(e => {
                    const error = e.status;
                    let desc: string;
                    if (e.errorDesc) {
                        desc = e.errorDesc
                    }
                    else {
                        desc = e.explanation
                    }
                    this.toastrService.error(desc, error, {
                        disableTimeOut: true,
                        tapToDismiss: true,
                        timeOut: 0
                    });
                })
            }
            else {
                console.error("RES ERROR IS NOT ARRAY, HANDLE IT", res.error)

                const error = res.error as StateResponseError;
                this.toastrService.error(error.explanation, error.status, {
                    disableTimeOut: true,
                    tapToDismiss: true,
                    timeOut: 0
                });

            }

        } else if (res.debug) {
            console.log(res.debug);
        }

        // this._destroy.next();
        // this._destroy.complete();
    }

    getError(error: FunctionalErrorsType) {
        const oriAttValue: string = error.errorOriAttVal;
        const pointer: string = error.errorPointer;
        let text = '';
        let desc = '';

        if (error.errorDesc) {
            desc = error.errorDesc;
        }
        else {
            desc = error.explanation
        }


        if (oriAttValue) {

            text = 'Λάθος Τιμή: ' + oriAttValue + '<br>';
        }
        if (pointer) {

            text = text.concat(pointer + '<br>');
        }

        this.toastrService.error(text, desc, {
            disableTimeOut: true,
            tapToDismiss: true,
            timeOut: 0
        }).onHidden.pipe(take(1)).subscribe(() => {

            console.log("Number of toasts:", this.toastrService.toasts.length - 1);
        });

        //let field = error.ErrPoiER12;
    }

    onFinalData(res: StateResponse) {
        const type = res.messageIn;
        const stateCode = res.stateCode;
        const subdomain = res.subdomain;


        console.log('notifications FinalData response: ', res);
        console.log('type: ', type);
        console.log('onFinalData: ', this.toastrService.toasts);

        this.toastrService.clear();
        this.toastrService.toasts.forEach(toast => {
            this.toastrService.clear(toast.toastId);
        });


        if (type === 'CC528A' || type === 'CC028A' || type === 'ID28A' || type === 'IB28A' || type === 'VA01A' || type === 'EF01A' || type === 'TF028A') {
            //   let LRN = res.data.HEAHEA.RefNumHEA4;
            const mrn = res.mrn;

            this.mainBroadcaster.declarationStates$.pipe(take(1)).subscribe(states => {
                const currentState = states[subdomain].current.stateCode

                console.log("notify currentState ", currentState)
                console.log("notify response state ", res.stateCode)
                if (currentState !== res.stateCode) {
                    //=====================
                    this.toastrService.success(
                        '<br>MRN: <b>' + mrn + '</b><br> Κατάσταση: <b>' + this.declarationState.transform(stateCode) + '</b>',
                        'Επιτυχής Υποβολή',
                        {
                            timeOut: 10000,
                            enableHtml: true
                        }
                    );
                }
                else {
                    //When called from modify or cancel do not show notification
                    //   if(!silent){

                    this.toastrService.info(
                        '', 'Δεν υπήρξε αλλαγή στην κατάσταση της διασάφησης.',
                        {
                            timeOut: 3000,
                        }
                    );
                    //    }


                }
            })

        }
        //else if (type === 'CC560A')

        /// If Rejected Show errors
        if (res.data.rejectionReason) {
            const rejectReason = res.data.rejectionReason;
            if (rejectReason) {
                this.toastrService.error(rejectReason, 'Αιτία Απόρριψης', {
                    disableTimeOut: true,
                    tapToDismiss: true,
                    timeOut: 0
                });
            }
        }

        if (res.data.actionToBeTaken) {
            const actionToBeTaken = res.data.actionToBeTaken;
            if (actionToBeTaken) {
                this.toastrService.error(actionToBeTaken, 'Ενέργειες που πρέπει να γίνουν', {
                    disableTimeOut: true,
                    tapToDismiss: true,
                    timeOut: 0
                });
            }
        }

        if (res?.declarationForm?._PRIVATE?.data?.FunctionalErrors) {
            res.declarationForm._PRIVATE.data.FunctionalErrors.forEach(element => {
                setTimeout(() => {
                    this.getError(element);
                }, 100)
            });
        }





    }

    notifyNewState(response: StateResponse) {
        const stateCode = response.stateCode;
        const lrn = response.lrn;
        const mrn = response.mrn;

        const title = 'Η κατάσταση του LRN ' + lrn + ' άλλαξε.';
        let message = 'Νέα Κατάσταση: ' + this.declarationState.transform(stateCode);

        if (mrn && mrn !== null) {
            message = 'Νέα Κατάσταση: <b>' + this.declarationState.transform(stateCode) + '</b>, MRN: <b>' + mrn + '</b>';
        }

        this.toastrService.info(message, title, {
            timeOut: 2500,
            enableHtml: true
        });
    }

    notifyNoChange(response: StateResponse) {
        const stateCode = response.stateCode;
        const lrn = response.lrn;

        const title = 'Η κατάσταση του LRN ' + lrn + ' δεν έχει αλλάξει.';
        const message = 'Κατάσταση: ' + this.declarationState.transform(stateCode);

        this.toastrService.info(message, title, {
            timeOut: 2500,
            enableHtml: true
        });
    }
    clear() {
        this.toastrService.clear();
    }

    editNotAllowed() {
        this.clear();
        this.toastrService.error('Η διασάφηση βρίσκεται σε κατάσταση που δεν επιτρέπει την τροποποίησή της.');
    }

    returnToPrevState() {
        return this.toastrService.info('', 'Επιστροφή στην προηγούμενη κατάσταση', {
            timeOut: 2000,
            positionClass: 'toast-top-center'
        }).onHidden.pipe(take(1));
    }
    cancellationReason(message, initByCustoms) {
        let title = 'Αιτία Ακύρωσης';

        if (initByCustoms) {
            title = 'Ακυρώθηκε απο το τελωνείο. Αιτία Ακύρωσης: '
        }
        this.toastrService.warning(message, title, {
            timeOut: 0,
            tapToDismiss: true,
        })
    }
    cancellationRefused(message) {
        const title = 'Αιτία Ακύρωσης';

        this.toastrService.error(message, title, {
            disableTimeOut: true,
            tapToDismiss: true,
            timeOut: 0

        }).onHidden.pipe(take(1)).subscribe(() => {

            console.log("Number of toasts:", this.toastrService.toasts.length - 1);
        });;
    }



}
