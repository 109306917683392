import { Component, OnInit } from '@angular/core';
import { Observable, tap, pairwise, map, startWith, skip, combineLatest } from 'rxjs';
import { ActivityLedsService } from './activity-leds.service';
import { MainBroadcasterService } from 'app/services/broadcaster.service';

@Component({
    selector: 'activity-leds',
    templateUrl: './activity-leds.component.html',
    styleUrls: ['./activity-leds.component.scss']
})
export class ActivityLedsComponent implements OnInit {
    networkOnline$: Observable<boolean>;
    serverOnline$: Observable<boolean>;
    icisOnline$: Observable<boolean>;
    icisUserLoggedIn$: Observable<boolean>;

    isLoginInProgress$: Observable<boolean>;
    tooltipIcisNet$: Observable<string>;
    tooltipNetwork$: Observable<string>;
    tooltipServer$: Observable<string>;

    counter = 0;
    constructor(private activityService: ActivityLedsService, private mainBroadcaster: MainBroadcasterService) {
        this.activityService.webSocketConnect();
    }
    ngOnInit(): void {
        this.networkOnline$ = this.activityService.networkOnline$;

        this.isLoginInProgress$ = this.mainBroadcaster.isLoginInProgress$.pipe(tap(value => console.log("isLoginInProgress", value)));

        this.serverOnline$ = this.activityService.serverOnline$.pipe(startWith(false), pairwise(), map(([prevValue, currValue], index) => {

            //After the emissions have settled, we set the right counter value so, one of the next conditions are triggered.
            //if network is disonnected at app start we set the counter to 1, so when it comes back up, the connected sound will be played and vice versa.
            if (index === 1) {
                this.counter = currValue === true ? 0 : 1;

            }

            if (index > 1) {


                if (prevValue === true && currValue === false && this.counter === 0) {

                    console.log('Playing Audio DisConnected')

                    this.playNotificationSound(currValue);
                    this.counter++

                }
                if (prevValue === false && currValue === true && this.counter !== 0) {
                    console.log('Playing Audio Connected')


                    this.playNotificationSound(currValue);

                    this.counter = 0;
                }
            }

            return currValue;
        }));

        this.icisOnline$ = this.activityService.icisOnline$/* .pipe(pairwise(), map(([prevValue, currValue], index) => {

            //Skip first 5 values, let it bacome stable
            if (index > 1) {
                console.log("Playing Audio prevValue", prevValue)
                console.log("Playing Audio currValue", currValue)
                console.log("Playing Audio counter", this.counter)


                if (prevValue === true && currValue === false && this.counter === 0) {

                    console.log('Playing Audio DisConnected')
                    this.playNotificationSound(currValue);
                    this.counter++

                }
                if (prevValue === false && currValue === true && this.counter !== 0) {
                    console.log('Playing Audio Connected')

                    this.playNotificationSound(currValue);

                    this.counter = 0;
                }
            }

            return currValue;
        })); */

        this.icisUserLoggedIn$ = this.mainBroadcaster.icisUser$.pipe(map(value => value.isLoggedIn));

        this.tooltipNetwork$ = this.networkOnline$.pipe(

            map((networkOnline) => {
                if (!networkOnline) {
                    return 'Δεν υπάρχει σύνδεση στο ίντερνετ';
                }

                if (networkOnline) {
                    return 'Σύνδεση στο ίντερνετ';
                }
            })
        );

        this.tooltipServer$ = combineLatest([
            this.icisOnline$,
            this.networkOnline$,

        ]).pipe(
            map(([serverOnline, networkOnline]) => {

                if (serverOnline && networkOnline) {
                    return 'Τοπικός διακομιστής ενεργός';
                }

                return 'Τοπικός διακομιστής ανενεργός';
            })
        );

        this.tooltipIcisNet$ = combineLatest([
            this.icisOnline$,
            this.serverOnline$,
            this.isLoginInProgress$,
            this.icisUserLoggedIn$,
            this.mainBroadcaster.icisUser$
        ]).pipe(
            map(([icisOnline, serverOnline, isLoginInProgress, icisUserLoggedIn, icisUser]) => {
                if (!serverOnline) {
                    return 'Άγνωστη κατάσταση ICISNet';
                }
                if (!icisOnline) {
                    return 'ICISNet offline';
                }
                if (isLoginInProgress) {
                    return 'ICISNet online\n Σύνδεση χρήστη σε εξέλιξη...';
                }
                if (icisUserLoggedIn) {
                    return 'ICISNet online\n ' + icisUser.user.name;
                }
                return 'ICISNet online\n Χρήστης μη συνδεδεμένος';
            })
        );
    }

    playNotificationSound(connected: boolean) {

        let audioFilePath: string;
        if (connected) {
            audioFilePath = 'assets/sounds/Oxygen-Sys-App-Positive.ogg';
        }
        else {
            audioFilePath = 'assets/sounds/Oxygen-Im-Cant-Connect.ogg';
        }
        const audio = new Audio(audioFilePath);
        audio.load();

        audio.play().catch((error) => {
            console.error('Error playing audio:', error);
        });
    }
}
