import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.model';
import { UserService } from 'app/core/user/user.service';
import { BusinessService } from 'app/main/businesses/services/business.service';
import { Office } from 'app/model/office.model';
import { PATHS } from 'app/navigation/paths';
import { ApiNodeService } from 'app/services/api.node.service';
import { OfficeService } from 'app/services/office.service';
import { Observable, Subject, take, takeUntil } from 'rxjs';

@Component({
    selector: 'toolbar-profile-menu',
    templateUrl: './toolbar-profile-menu.component.html',
    styleUrls: ['./toolbar-profile-menu.component.scss']
})
export class ToolbarProfileMenuComponent implements OnInit {

    offices: Office[];
    currentOffice: Office;
    private _destroy = new Subject<void>();
    user$: Observable<User>
    constructor(
        private _authService: AuthService,
        private _officeService: OfficeService,
        private _businessService: BusinessService,
        private _router: Router,
        private _userService: UserService,
        private _apiNodeService: ApiNodeService) { }

    ngOnInit(): void {

        this.user$ = this._userService.user$

        this._officeService.offices$.pipe(takeUntil(this._destroy)).subscribe(offices => {
            this.offices = offices
        })

        this._officeService.currentOffice$.pipe(takeUntil(this._destroy)).subscribe(office => {
            this.currentOffice = office
        })
    }

    logout() {
        this._authService.signOut().pipe(take(1)).subscribe(() => {

            this._router.navigate([PATHS.signIn]);

        });
    }
    changeOffice(office: Office) {
        this._router.navigate([PATHS.start(office?.id)])
        this._apiNodeService.icisLogout().subscribe();
        this._officeService.setCurrentOffice(office)
        const business = this._businessService.getBusinessById(office?.business_id)
        this._businessService.setCurrentBusiness(business)
    }
    myprofile() {
        this._router.navigate([PATHS.profile]);
    }
}
