import { Contact } from 'app/main/apps/contacts/contact.model';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, FormArray, Form } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutoCompConfigValues } from 'app/main/main-building-blocks/form-fields/custom-autocomplete/config.model';
import { ExtCustomFunctions } from 'app/main/main-building-functions/ext-custom-functions.service';
import { customsItem, User } from 'app/model/api-model';
import { ApiEndPoints, ApiService } from 'app/services/api.service';
import { DeclarationStates, MainBroadcasterService } from 'app/services/broadcaster.service';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, debounceTime, switchMap, take, distinctUntilChanged, tap, filter } from 'rxjs/operators';
import { EAitisiService } from './e-aitisi.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ArchiveResult, ArchiveSaveDataCommon } from '../archive/archive.model';
import { DeclarationStateService, StateResponse } from 'app/services/declaration-state.service';
import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { Declarant } from 'app/core/user/user.model';
import { eAitisiDeclarationForm } from 'app/main/apps/e-aitisi/_models/declaration/declaration-form.model';
import { LrnService } from 'app/services/lrn.service';
import { ActivityLedsService } from 'app/main/ext-components/activity-leds/activity-leds.service';
import { ePaymentResponseModel } from 'app/main/apps/e-payment/_models/e-payment-response.model';
import { OpenDialogsService, RemittanceInput } from 'app/services/openDialogs.service';
import { DeclarationForm } from 'app/main/apps/export/declaration/_models/declaration-form.model';
import { SharedDeclBisService } from 'app/services/submitServices/sharedDeclBis.service';
import { SubmitFormService } from 'app/services/submitServices/submitForm.service';
import { ComCodModel } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm.model';
import { ComCodForm } from 'app/main/apps/export/declaration/_models/gooitegds/comcodgoditm/comcodgodtm-form.model';



@Component({
    selector: 'app-e-aitisi',
    templateUrl: './e-aitisi.component.html',
    styleUrls: ['./e-aitisi.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [EAitisiService, SharedDeclBisService]
})
export class EAitisiComponent implements OnInit {
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    @ViewChildren('goodsPanel') _goodsPanel: QueryList<MatExpansionPanel>;
    @ViewChildren('combinedCode') _combinedCode: QueryList<ElementRef>;

    subdomain = 'ICS_DETE';
    eAitisiForm: FormGroup;
    private: FormGroup;
    heahea: FormGroup;
    tradec: FormGroup;
    trarep: FormGroup;
    cusoff: FormGroup;
    gooitegds: FormArray;
    refdecl: FormArray;
    tasks: FormArray;

    acceptanceDate: FormControl;
    amendAcceptanceDate: FormControl;

    color1 = '';
    color2 = '';

    eori: string;
    street: string;
    address2: string;
    country: string;

    stateCode: string;

    showButtons: boolean;
    formDisabled: boolean;

    codes: any[] = [];

    traders: Contact[] = [];
    allDeclarants: Declarant[] = [];
    declarants: Declarant[] = [];

    originalDeclarant: Declarant;
    customs: customsItem[] = [];

    declConfig: AutoCompConfigValues;
    consConfig: AutoCompConfigValues;
    customsConfig: AutoCompConfigValues;

    serverOnline$: Observable<boolean>;
    icisOnline$: Observable<boolean>;

    checkboxChecked: boolean;



    payments = [
        { code: 'A', desc: 'Πληρωμή τοις μετρητοίς' },
        { code: 'H', desc: 'Ηλεκτρονική μεταφορά κεφαλαίων' }
    ];


    parentSubdomain: string;

    private _destroy: Subject<void>;

    get declarant(): FormControl {
        return this.eAitisiForm.get('_PRIVATE').get('declarant') as FormControl;
    }

    get _trader(): FormControl {
        return this.eAitisiForm.get('_PRIVATE').get('trader') as FormControl;
    }


    get customsTasks(): FormControl {
        return this.eAitisiForm.get('HEAHEA').get('CustomsTasks') as FormControl;
    }

    get manualRF(): FormControl {
        return this.eAitisiForm.get('HEAHEA').get('ManualRF') as FormControl;
    }

    constructor(
        private eAitisiService: EAitisiService,
        public dialogRef: MatDialogRef<EAitisiComponent>,
        private cf: ExtCustomFunctions,
        private dbQuery: ApiService,
        private _mainBroadcaster: MainBroadcasterService,
        private dateTransform: DateTransformPipe,
        private cd: ChangeDetectorRef,
        private declarationStateService: DeclarationStateService,
        private lrnService: LrnService,
        private activityService: ActivityLedsService,
        private openDialogService: OpenDialogsService,
        private sharedDeclBisService: SharedDeclBisService,
        private submitFormService: SubmitFormService,
        @Inject(MAT_DIALOG_DATA) public data: { declaration: ArchiveSaveDataCommon, referrerDeclarationId: string }

    ) {
        console.log('eAitisi COMP INIT');
        this.showButtons = true;
        this._destroy = new Subject<void>();

        this.acceptanceDate = new FormControl({ value: '', disabled: true });
        this.amendAcceptanceDate = new FormControl({
            value: '',
            disabled: true
        });
        this.serverOnline$ = this.activityService.serverOnline$;
        this.icisOnline$ = this.activityService.icisOnline$;




    }

    ngOnInit(): void {

        const eAitisform$ = this.eAitisiService.eAitisiForm$.pipe(
            tap((form) => {
                this.declareProperties(form)
                //this._mainBroadcaster.updateDeclarationForms({ [this.subdomain]: this.eAitisiForm })
                this.getCustoms().subscribe()
                this.getDeclarants().subscribe()
                this.getTraders().subscribe()
                this.valuechangesSubs()

            }),
            switchMap(() => this.declarationState()),
            switchMap(() => {
                if (!this.data.declaration) {
                    return this.onDeclarantChange()
                }
                return of(null)
            }
            ),
            switchMap(() => this.sharedDeclBisService.onDeclFormValuechanges(this.eAitisiForm, this.subdomain)),
            tap(() => {
                //update subdomain to ICS_DETE after patching
                this._mainBroadcaster.updateActiveSubdomain(this.subdomain);
                this.cd.detectChanges()
            }),
            takeUntil(this._destroy)
        );


        this.eAitisiService
            .fillForm(this.data.referrerDeclarationId)
            .pipe(
                take(1),
                switchMap(([subdomain, declarationForm]) => {
                    //after filling the form set activeSubdomain
                    this.parentSubdomain = subdomain;
                    this.getOriginalDeclarant(declarationForm);

                    return eAitisform$;
                }))
            .subscribe()



        this.eAitisiService.onRefusedEdit().pipe(takeUntil(this._destroy)).subscribe()
        this.sharedDeclBisService.ePayment().pipe(takeUntil(this._destroy)).subscribe()
        this.sharedDeclBisService.modifyCancelDecl().pipe(takeUntil(this._destroy)).subscribe();

        this.declConfig = this.eAitisiService.declConfig;
        this.consConfig = this.eAitisiService.consConfig;
        this.customsConfig = this.eAitisiService.customsConfig;

        if (this.manualRF.value === '1') {
            this.checkboxChecked = true
        }
        else { this.checkboxChecked = false }



        ////LOAD SAVE DECLARATIO==========================================================
        if (this.data.declaration) {
            this.eAitisiService.loadSavedForm(this.data.declaration);

            this.stateCode = this.eAitisiForm.get('_PRIVATE').get('stateCode').value;

            if ('HEAHEA' in this.data.declaration.declarationForm) {
                if ('AccDatHEA158' in this.data.declaration.declarationForm.HEAHEA) {
                    const acceptDate = this.data.declaration.declarationForm.HEAHEA.AccDatHEA158
                    if (acceptDate) {
                        this.acceptanceDate.setValue(
                            this.dateTransform.formatDate(acceptDate, 'dd/mm/yyyy')
                        );
                    }
                }
            }
            //For previous declarations that manualRF was not used
            if (!this.eAitisiForm.get('HEAHEA').get('ManualRF').value) {
                this.checkboxChecked = false
            }

            this.declarationStateService.transmitStateCode(this.eAitisiForm)

        }
        this.dbQuery.get_options(ApiEndPoints.eAitisi_codes)
            .pipe(take(1))
            .subscribe(codes => {
                this.codes = codes;
            });




    }
    ngOnDestroy(): void {
        console.log('eAITISI COMP DESTROYED');

        //restore parent subdomain
        this.reset_eAitisi()

        this._destroy.next();
        this._destroy.complete();
    }

    declareProperties(declarationForm): Observable<FormGroup<eAitisiDeclarationForm>> {


        console.log('followSubscriptionChain declareProperties declarationForm', declarationForm.value);

        this.eAitisiForm = declarationForm;

        this.private = declarationForm.get('_PRIVATE') as FormGroup;
        this.heahea = declarationForm.get('HEAHEA') as FormGroup;
        this.tradec = declarationForm.get('TRADEC') as FormGroup;
        this.trarep = declarationForm.get('TRAREP') as FormGroup;
        this.cusoff = declarationForm.get('CUSOFF') as FormGroup;
        this.refdecl = declarationForm.get('REFDECL') as FormArray;
        this.gooitegds = declarationForm.get('GOOITEGDS') as FormArray;
        this.tasks = declarationForm.get('TASKS') as FormArray;
        this.stateCode = declarationForm.value._PRIVATE.stateCode;

        return of(this.eAitisiForm)
    }

    getOriginalDeclarant(declarationForm: FormGroup<DeclarationForm>) {
        if (declarationForm) {
            this.originalDeclarant = declarationForm?.get('_PRIVATE').get('declarant').value

        }
        else if (this.data.declaration) {
            this.originalDeclarant = this.data.declaration.declarationForm._PRIVATE.declarant
            this._mainBroadcaster.updateCurrentDeclarant(this.originalDeclarant, true)


        }
    }
    getCustoms() {

        const countryCode = this.cusoff.get('_countryCode');

        return this.eAitisiService.getCustoms(countryCode.value).pipe(tap((data: customsItem[]) => {
            this.customs = data;
        }), take(1));

    }

    getDeclarants() {
        return this.eAitisiService.getDeclarants().pipe(
            tap((data: Declarant[]) => {
                this.allDeclarants = data;
                this.declarants = this.cf.filterDeclarants(data, this._trader.value);
            }));
    }

    getTraders() {
        return this.eAitisiService.getTraders().pipe(tap((data: Contact[]) => {
            this.traders = data;
        }));
    }
    declarationState(): Observable<[DeclarationStates, boolean]> {

        return this.sharedDeclBisService.declarationState(this.eAitisiForm).pipe(tap(([states, disabled]) => {

            this.formDisabled = disabled;
            this.showButtons = !disabled;

            const state: StateResponse = states[this.subdomain].current;

            if (state?.stateCode) {
                this.stateCode = state.stateCode;
            }
            console.log('followSubscriptionChain declarationState ', state, 'disabled', disabled);
        }))
    }

    onDeclarantChange(): Observable<{ lrn: string }> {
        return this._mainBroadcaster.currentDeclarant$.pipe(
            filter(currentDeclarant => Boolean(currentDeclarant?.declarant)),
            switchMap(currentDeclarant => {
                console.log('followSubscriptionChain onDeclarantChange ', currentDeclarant);

                console.log("onDeclarantChange declarant", currentDeclarant)
                console.log("onDeclarantChange formDisabled", this.formDisabled)
                console.log("onDeclarantChange declarationForm", this.eAitisiForm.value)
                if (currentDeclarant && !this.formDisabled && !currentDeclarant.onInit) {
                    return this.lrnService.setNewLrn(this.eAitisiForm).pipe(tap(lrn => console.log("onDeclarantChange lrn", lrn)))
                }
                return of(null)

            }),
            takeUntil(this._destroy)
        )
    }

    valuechangesSubs() {
        const countryCode = this.cusoff.get('_countryCode');

        this.heahea
            .get('AccDatHEA158')
            .valueChanges.pipe(takeUntil(this._destroy))
            .subscribe(value => {
                if (value) {
                    this.acceptanceDate.setValue(this.dateTransform.formatDate(value, 'dd/mm/yyyy'));
                }
            });

        this.heahea
            .get('AmeAccDatHEA602')
            .valueChanges.pipe(takeUntil(this._destroy))
            .subscribe(value => {
                if (value) {
                    this.amendAcceptanceDate.setValue(this.dateTransform.formatDate(value, 'dd/mm/yyyy'));
                }
            });

        this.tradec
            .get('DeclarantObject')
            .valueChanges.pipe(takeUntil(this._destroy))
            .subscribe(value => {

                this._trader.setValue(value)
                this.eAitisiForm.get('_PRIVATE').get('trader').setValue(value)
                this.eAitisiService.patchTradec(this.tradec, value)


            });


        this.trarep
            .get('DeclarantObject')
            .valueChanges.pipe(takeUntil(this._destroy))
            .subscribe(value => {
                this.eAitisiService.patchTrarep(this.trarep, value)
                this.eAitisiForm.get('_PRIVATE').get('declarant').setValue(value)
                this.declarant.setValue(value)
                this._mainBroadcaster.updateCurrentDeclarant(value)

            })


        countryCode.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(300),
                switchMap((country: string) => {
                    console.log(country);
                    if (country.length === 2) {
                        return this.eAitisiService.getCustoms(country);
                    }
                })
            )
            .subscribe(customs => {
                if (customs === null) {
                    this.cusoff.get('RefNumIMPCUSOFF').setValue(null);
                    this.cusoff.get('RefNumIMPCUSOFF').disable();
                } else {
                    if (countryCode.disabled) {
                        this.cusoff.get('RefNumIMPCUSOFF').disable();
                    } else {
                        this.cusoff.get('RefNumIMPCUSOFF').enable();
                    }
                    this.customs = customs;
                }
            });


        this._trader.valueChanges.pipe(takeUntil(this._destroy)).subscribe(trader => {

            this.declarants = this.cf.filterDeclarants(this.allDeclarants, trader)

        })


        this.customsTasks.valueChanges.pipe(takeUntil(this._destroy)).subscribe(value => {

            if (value === '0') {
                this.tasks.reset()
            }
            else {
                this.eAitisiService.setDefaultTasks(this.eAitisiForm)

            }
        })

    }

    add(formArrayName: string) {
        this.eAitisiService.addFormArray(formArrayName);
        this.cd.detectChanges();

        if (formArrayName === 'GOOITEGDS') {
            setTimeout(() => {
                this._goodsPanel.last.open();
            }, 100);
            setTimeout(() => {
                this._combinedCode.last.nativeElement.focus();
            }, 500);
        }
    }

    delete(formArrayName, i) {
        this.eAitisiService.deleteFormArray(formArrayName, i);
    }

    hideAdd(formArray: FormArray) {
        return this.cf.hideAdd(formArray, 99);
    }
    hideDelete(formArray: FormArray) {
        return this.cf.hideDelete(formArray);
    }

    onCloseConfirm() {

        this.dialogRef.close();

    }
    changeStyle1(event) {
        this.color1 = this.cf.changeStyle1(event);
    }
    changeStyle2(event) {
        if (this.eAitisiForm.valid) {
            this.color2 = this.cf.changeStyle2(this.eAitisiForm, event);
        }
    }
    expandPanel(matExpansionPanel, event) {
        this.cf.expandPanel(matExpansionPanel, event);
    }

    edit() {
        this.cf.editDeclaration(this.eAitisiForm);
    }
    onSubmit() {

        this.eAitisiService.submit();
    }
    modifyDeclaration() {
        this._mainBroadcaster.sendModifyDeclaration();
    }
    cancelDeclaration() {
        this._mainBroadcaster.sendCancelDeclaration();
    }

    cancelModify() {
        this.eAitisiService.cancelEdit()
    }
    checkState() {
        this.declarationStateService.checkState(this.eAitisiForm);
    }


    reset_eAitisi() {

        this._mainBroadcaster.updateActiveSubdomain(this.parentSubdomain);

        if (this.originalDeclarant) {
            this._mainBroadcaster.updateCurrentDeclarant(this.originalDeclarant)
        }

        //if form has been submitted delete it from everywhere
        if (this.stateCode !== 'Pre-Submitted') {
            this._mainBroadcaster.updateDeclarationForms({
                [this.subdomain]: null
            });
        }

        if (this.stateCode === 'Edit') {
            this.eAitisiService.removeLocalMRN(this.eAitisiForm.get('HEAHEA').get('DocNumHEA5').value)
        }
        console.log("Subdomain on reset eAitisi", this.subdomain)
        this._mainBroadcaster.updateDeclarationStates({
            [this.subdomain]: { stateCode: 'Pre-Submitted' }
        })

        this._mainBroadcaster.updateFormDisabled({ [this.subdomain]: null });



    }

    ePayment() {

        this.dbQuery.checkRemittance({ mrn: this.eAitisiForm.get('_PRIVATE').get('mrn').value })
            .pipe(
                switchMap((remittance: ePaymentResponseModel) => {
                    const remittanceInput: RemittanceInput = {
                        private: this.eAitisiForm.get('_PRIVATE').value,
                        remittance
                    }
                    return this.openDialogService.openEPaymentDialog([remittanceInput])
                        .pipe(
                            tap(value => console.log("ePayment dialog response", value))
                        )
                }))

            .subscribe()
    }

    getXML() {
        this.submitFormService.getDeclarationXML(this.subdomain).subscribe({
            next: successMessage => {
                console.log(successMessage);
                // Additional actions on success, if needed
            },
            error: error => {
                console.error("Failed to save the file", error);
                // Handle errors here, such as showing a user-friendly message
            }
        });
    }

    decomposeComCode(comCodeGodItm: FormGroup<ComCodForm>) {
        console.log('decontructComCode', comCodeGodItm.value)

        const combinedComCode = comCodeGodItm.get('_combinedComCode') as FormControl;

        if (combinedComCode.value.length < 10) {

            return;
        }

        comCodeGodItm.get('ComNomCMD1').setValue(combinedComCode.value.slice(0, 8));
        comCodeGodItm.get('TARCodCMD1').setValue(combinedComCode.value.slice(8, 10));
    }

    onChange(event) {

        if (event.checked === true) {
            this.manualRF.setValue('1')
        }
        else {
            this.manualRF.setValue('0')

        }

    }


}
