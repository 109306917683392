import { TransportDocuments, PreviousDocuments } from './../../main/apps/transit/t2l-pous/_models/pous/pous.model';
import { DateTransformPipe } from 'app/services/pipes/dateTransform.pipe';
import { DEFKDeclarationModel } from './../../main/apps/excise/defk/_models/EF15A/declaration.model';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { eAitisiDeclarationModel } from 'app/main/apps/e-aitisi/_models/declaration/declaration.model';
import { VA10ADeclarationModel } from 'app/main/apps/excise/dao/_models/VA10A/daoCancel.model';
import { VA13ADeclarationModel } from 'app/main/apps/excise/dao/_models/VA13A/va13a-declaration.model';
import { DaoDeclarationModel } from 'app/main/apps/excise/dao/_models/VA15A/declaration.model';
import { VA18ADeclarationModel } from 'app/main/apps/excise/dao/_models/VA18A/va18a-declaration.model';
import { CancelModel } from 'app/main/apps/export/cancel-declaration/_models/cancel.model';
import { DeclarationForm } from 'app/main/apps/export/declaration/_models/declaration-form.model';
import { Declaration } from 'app/main/apps/export/declaration/_models/declaration.model';
import { ImpDeclarationModule } from 'app/main/apps/import/imp-declaration/imp-declaration.module';
import { ImportDeclarationForm } from 'app/main/apps/import/_models/declaration/declaration-form.model';
import { ImportDeclarationModel } from 'app/main/apps/import/_models/declaration/declaration.model';
import { T2LCancelDeclarationModel } from 'app/main/apps/transit/t2l/_models/cancel-declaration/declaration.model';
import { T2LDeclarationForm } from 'app/main/apps/transit/t2l/_models/declaration/declaration-form.model';
import { T2LDeclarationModel } from 'app/main/apps/transit/t2l/_models/declaration/declaration.model';
import { TirDeclarationForm } from 'app/main/apps/transit/tir/_models/declaration/declaration-form.model';
import { TirDeclarationModel } from 'app/main/apps/transit/tir/_models/declaration/declaration.model';
import { T2LHeaheaTF013AModel } from 'app/main/apps/transit/_models/heahea-TF013A/heaheaTF013A.model';
import { take } from 'rxjs/operators';
import { DeclarationForms, MainBroadcasterService } from '../broadcaster.service';
import { EF13ADeclarationModel } from 'app/main/apps/excise/defk/_models/EF13A/ef13a-declaration.model';
import { EF10ADeclarationModel } from 'app/main/apps/excise/defk/_models/EF10A/defkCancel.model';
import { TirCancelModel } from 'app/main/apps/transit/tir/_models/cancel-declaration/declaration.model';
import { ProofRequest } from 'app/main/apps/transit/t2l-pous/_models/pous/pous.model';
import { PousDocumentsModel } from 'app/model/private-controls.model';

@Injectable({
    providedIn: 'root'
})
export class CreateSubmitFormService {
    constructor(private mainBroadcaster: MainBroadcasterService, private dateTimeService: DateTransformPipe) { }

    getCurrentDecl(subdomain: string): any {
        let cloned: FormGroup;
        let original: FormGroup;

        this.mainBroadcaster.declarationForms$.pipe(take(1)).subscribe((declarationForms: DeclarationForms) => {
            const declarationForm = declarationForms[subdomain];
            const declarationValue = declarationForm.getRawValue();

            switch (subdomain) {
                case 'EXP_DECL': {
                    cloned = new FormGroup(new DeclarationForm(new Declaration(declarationValue)));
                    break;
                }
                case 'IMP_DECL': {
                    cloned = new FormGroup(new ImportDeclarationForm(new ImportDeclarationModel(declarationValue)));
                    break;
                }

                case 'T2L': {
                    cloned = new FormGroup(new T2LDeclarationForm(new T2LDeclarationModel(declarationValue)));
                    break;
                }
                case 'TIR': {
                    cloned = new FormGroup(new TirDeclarationForm(new TirDeclarationModel(declarationValue)));
                    break;
                }
            }

            original = declarationForm;
        });

        return { original, cloned };
    }

    createForm(declarationForm: any, messageType: string): any {
        console.log('fn createSubmitFormService createForm declarationForm', declarationForm);
        console.log('createForm messageType', messageType);


        const submitForm = { ...declarationForm };

        switch (messageType) {
            //==========================
            case 'CC515A': {
                return this.CC515A(submitForm);
            }
            case 'CC513A': {
                console.log("createForm returning CC513A", submitForm)

                return this.CC513A(submitForm);
            }
            case 'CC514A':
            case 'CC014A':
            case 'ID14A': {
                {
                    this.setCancelHeader(submitForm);
                    this.setCancelHeaderLNG(submitForm);

                    return submitForm;

                }
            }
            case 'IB14A': {
                return this.setCancelHeader(submitForm);

            }
            //==========================
            case 'ID15A': {
                console.log("createForm returning ID15A", submitForm)

                return this.ID15A(submitForm);
            }
            case 'ID13A': {
                console.log("createForm returning ID13A", submitForm)

                return this.ID13A(submitForm);
            }
            //==========================
            case 'TF015A': {
                return this.TF015A(submitForm);
            }
            case 'TF013A': {
                return this.TF013A(submitForm);
            }
            case 'TF014A': {
                return this.TF014A(submitForm);
            }

            //===========================
            case 'CC015B': {
                return this.CC015B(submitForm);
            }
            case 'CC013B': {
                return this.CC013B(submitForm);
            }
            //============================
            case 'VA18A': {
                return this.VA18A(submitForm);
            }
            case 'VA13A': {
                return this.VA13A(submitForm);
            }
            case 'VA10A': {
                return this.VA10A(submitForm);
            }
            //===============================
            case 'IB13A': {
                return this.IB13A(submitForm);
            }

            //==============================
            case 'EF15A': {
                return this.EF15A(submitForm);
            }
            case 'EF13A': {
                return this.EF13A(submitForm);
            }
            case 'EF10A': {
                return this.EF10A(submitForm);
            }
            case 'POUS': {
                return this.POUS(submitForm);
            }
            default: {
                console.log("createForm returning default", submitForm)
                return submitForm;
            }


        }
    }

    CC515A(submitForm): Declaration {
        console.log("fn createSubmitFormService CC515A")

        this.fixDelter(submitForm);

        return submitForm;
    }

    CC513A(submitForm: Declaration): Declaration {
        console.log("fn createSubmitFormService CC513A")

        submitForm = this.fixDelter(submitForm);

        submitForm = this.setAmendDatePlace(submitForm);

        submitForm = this.setModifyRefNumber(submitForm);

        submitForm = this.deleteDates(submitForm);

        return submitForm;
    }

    setCancelHeader(submitForm: CancelModel): CancelModel {

        submitForm.HEAHEA.DatOfCanReqHEA147 = submitForm._PRIVATE.cancellationDate;
        submitForm.HEAHEA.CanReaHEA250 = submitForm._PRIVATE.cancellationReason;

        return submitForm;
    }

    setCancelHeaderLNG(submitForm) {

        submitForm.HEAHEA.CanReaHEA250LNG = 'EL';

        return submitForm;
    }

    ID15A(submitForm): ImpDeclarationModule {
        this.fixDelter(submitForm);
        this.setPaymentMethod(submitForm);

        return submitForm;
    }

    ID13A(submitForm): ImpDeclarationModule {
        console.log("fn createSubmitFormService ID13A")

        this.fixDelter(submitForm);
        this.setPaymentMethod(submitForm);

        submitForm = this.setAmendDatePlace(submitForm);

        submitForm = this.setModifyRefNumber(submitForm);

        submitForm = this.deleteDates(submitForm)


        return submitForm;
    }

    TF015A(submitForm: T2LDeclarationModel): T2LDeclarationModel {
        console.log("fn createSubmitFormService TF015A")

        const GOOITEGDS = submitForm.GOOITEGDS
        const declType = submitForm.HEAHEA.TypOfDecHEA24;

        const length = GOOITEGDS.length;

        for (let i = 0; i < length; i++) {
            GOOITEGDS[i].DecTypGDS15 = declType;
        }

        return submitForm;
    }

    TF013A(submitForm): T2LDeclarationModel {
        console.log("fn createSubmitFormService TF013A")

        console.log('TF013 submitForm', submitForm);

        const heahea = submitForm.HEAHEA
        const GOOITEGDS = submitForm.GOOITEGDS
        const declType = submitForm.HEAHEA.TypOfDecHEA24;

        console.log('TF013 submitForm oldHEAHEA', heahea);

        const length = GOOITEGDS.length;

        for (let i = 0; i < length; i++) {
            GOOITEGDS[i].DecTypGDS15 = declType;
        }

        //sbustitute HEAHEA because they are very different
        submitForm.HEAHEA = new T2LHeaheaTF013AModel(heahea);
        console.log('TF013 new submitForm', submitForm);

        return submitForm;
    }
    TF014A(submitForm): T2LCancelDeclarationModel {
        this.setCancelHeader(submitForm);

        return submitForm;
    }
    CC015B(submitForm): TirDeclarationModel {
        console.log("fn createSubmitFormService CC015B")

        return submitForm;
    }

    CC013B(submitForm): TirDeclarationModel {
        console.log("fn createSubmitFormService CC013B")

        //Amendment Type Flag
        //0: Amend all itels of original declaration
        //1: Amend only original guagua Data, 
        submitForm.CTLCL1.AmeTypFlaCL628 = '0'

        delete submitForm.HEAHEA.RefNumHEA4;

        submitForm = this.setAmendDatePlace(submitForm);

        submitForm = this.deleteDates(submitForm)

        return submitForm;
    }

    VA18A(submitForm: DaoDeclarationModel) {

        const mrn = submitForm.Body.VehicleArrivalDeclarationRegistration.VehicleArrival.DeclarationReferenceNumber;
        const arrivalDate = submitForm.Body.VehicleArrivalDeclarationRegistration.VehicleArrival._arrivalDate;

        const form = new VA18ADeclarationModel(submitForm);


        form.Body.VehicleArrivalDeclarationReception.VehicleArrivalReceivedDate.DeclarationReferenceNumber = mrn;
        form.Body.VehicleArrivalDeclarationReception.VehicleArrivalReceivedDate.ReceivedDate = arrivalDate;

        return form;

    }
    VA13A(submitForm: DaoDeclarationModel) {

        const body = submitForm.Body.VehicleArrivalDeclarationRegistration;

        const form = new VA13ADeclarationModel(submitForm);


        form.Body.VehicleArrivalDeclarationAmendment = body;

        const lrn = submitForm._PRIVATE.lrn;

        form.Body.VehicleArrivalDeclarationAmendment.VehicleArrival.LocalReferenceNumber = lrn

        return form;

    }
    VA10A(submitForm: VA10ADeclarationModel) {

        const mrn = submitForm._PRIVATE.mrn;
        const cancellationReason = submitForm._PRIVATE.cancellationReason;

        submitForm.Body.VehicleArrivalDeclarationCancellation.VehicleArrival.DeclarationReferenceNumber = mrn
        submitForm.Body.VehicleArrivalDeclarationCancellation.VehicleArrival.CancellationReason = cancellationReason

        return submitForm;

    }

    IB13A(submitForm: eAitisiDeclarationModel) {

        //we pass it through the constructor because there are different FormGroups between IB15 and IB13
        let form = new eAitisiDeclarationModel(submitForm);

        form.HEAHEA.AmdDatHEA599 = this.dateTimeService.convertDate(new Date(), 'yyyyMMdd');

        form = this.setModifyRefNumber(form);

        return form;

    }

    EF15A(submitForm: DEFKDeclarationModel) {

        const paymentMethod = submitForm.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesDeclaration.PaymentMethodCode;
        const taxesRow = submitForm.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesRow
        const afm = submitForm.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesDeclaration.ClearingAgent.ClearingAgentAfm
        let eori = submitForm.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesDeclaration.ClearingAgent.ClearingAgentEORI
        let traderId = submitForm.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesObligee.ObligeeIdentification


        eori = eori.slice(-9)
        traderId = traderId.slice(-9)

        taxesRow.forEach(e => {
            const caltax = e.TaxComputation

            caltax.forEach(tax => {
                tax.PaymentMethodCode = paymentMethod
                if (!tax.SuspensionFlag) {
                    tax.SuspensionFlag = '0';
                }
                if (!tax.TaxBase) {
                    tax.TaxBase = '0';
                }
                if (!tax.TaxRate) {
                    tax.TaxRate = '0';
                }
            })

        })

        submitForm.Body.DeclarationOfExciseTaxesRegistration.SubmittingOperator.SubmittingOperatorIdentification = eori ?? afm
        submitForm.Body.DeclarationOfExciseTaxesRegistration.SubmittingOperator.SubmittingTraderIdentification = traderId

        return submitForm;
    }

    EF13A(submitForm: DEFKDeclarationModel) {
        //EF13A takes MRN as MRN
        //and LRN as initial EF15A LRN

        submitForm = this.EF15A(submitForm)

        const body = submitForm.Body.DeclarationOfExciseTaxesRegistration;

        const form = new EF13ADeclarationModel(submitForm);


        form.Body.DeclarationOfExciseTaxesAmendment = body;

        const mrn = submitForm._PRIVATE.mrn;
        const lrn = submitForm._PRIVATE.lrn;
        submitForm.Body.DeclarationOfExciseTaxesRegistration.ExciseTaxesDeclaration.LocalReferenceNumber = lrn

        return form;

    }

    EF10A(submitForm: EF10ADeclarationModel) {

        const mrn = submitForm._PRIVATE.mrn;
        const cancellationReason = submitForm._PRIVATE.cancellationReason;

        submitForm.Body.DeclarationOfExciseTaxesCancellation.ExciseTaxesDeclaration.DeclarationReferenceNumber = mrn
        submitForm.Body.DeclarationOfExciseTaxesCancellation.ExciseTaxesDeclaration.CancellationReason = cancellationReason

        return submitForm;

    }

    POUS(submitForm: ProofRequest) {


        const ProofInformationT2LT2LF = submitForm.ProofInformationT2LT2LF;
        const GoodsShipmentForT2LT2LF = ProofInformationT2LT2LF.GoodsShipmentForT2LT2LF;
        const GoodsItemsForT2LT2LF = GoodsShipmentForT2LT2LF.GoodsItemsForT2LT2LF;
        const TransportEquipments = GoodsShipmentForT2LT2LF.TransportEquipments;
        const PreviousDocuments = GoodsShipmentForT2LT2LF.PreviousDocuments;


        const GoodsPrivate = submitForm._PRIVATE.goodsPrivate;

        GoodsShipmentForT2LT2LF.ContainerIndication = GoodsShipmentForT2LT2LF.ContainerIndication === '1' ? "true" : "false"

        GoodsItemsForT2LT2LF.forEach(item => {
            item.Commodity.HarmonizedSystemSubHeadingCode = item.Commodity.HarmonizedSystemSubHeadingCode.substring(0, 6);
        })

        let transportDocs = [];

        let i = 0;
        GoodsPrivate.forEach(good => {
            transportDocs.push(...good.DOCUMENTS.filter(doc => doc?.docObject?.type === 'T'));


            const supportiveDocs = good.DOCUMENTS.filter(doc => doc?.docObject?.type === 'S');
            const additionalRefs = good.DOCUMENTS.filter(doc => doc?.docObject?.type === 'A');
            const previousDocs = good.DOCUMENTS.filter(doc => doc?.docObject?.type === 'P');


            console.log("fn createSubmitFormService POUS DOCUMENTS", good.DOCUMENTS)
            console.log("fn createSubmitFormService POUS transportDocs", transportDocs)
            console.log("fn createSubmitFormService POUS supportiveDocs", supportiveDocs)
            console.log("fn createSubmitFormService POUS additionalRefs", additionalRefs)
            console.log("fn createSubmitFormService POUS previousDocs", previousDocs)

            previousDocs.forEach(doc => {
                PreviousDocuments.push({ Type: doc?.docObject.code, ReferenceNumber: doc?.ReferenceNumber })
            })


            supportiveDocs.forEach(doc => {
                GoodsItemsForT2LT2LF[i].SupportingDocuments.push({ Type: doc?.docObject.code, ReferenceNumber: doc?.ReferenceNumber })
            })

            additionalRefs.forEach(doc => {
                GoodsItemsForT2LT2LF[i].AdditionalReferences.push({ Type: doc?.docObject.code, ReferenceNumber: doc?.ReferenceNumber })
            })


            i++;

        })

        //Remove Duplicates from transportDocs;
        transportDocs = transportDocs.filter((doc, index, self) =>
            index === self.findIndex((t) => (t.ReferenceNumber === doc.ReferenceNumber))
        )


        console.log("fn createSubmitFormService POUS transportDocs", transportDocs)



        transportDocs.forEach((doc: PousDocumentsModel) => {
            console.log("fn createSubmitFormService POUS doc", doc)
            const td: TransportDocuments = {
                Type: doc?.docObject?.code,
                ReferenceNumber: doc?.ReferenceNumber
            }
            console.log("fn createSubmitFormService POUS td", td)
            submitForm.ProofInformationT2LT2LF.GoodsShipmentForT2LT2LF.TransportDocuments.push(td);
        })

        console.log("fn createSubmitFormService POUS submitForm", submitForm)

        //If TransportEquipment length === 1, then add all items to GoodsReferences if not already there

        if (TransportEquipments.length === 1) {
            const goodsReferences = TransportEquipments[0].GoodsReferences;

            GoodsItemsForT2LT2LF.forEach((item, index) => {
                if (!goodsReferences.includes(index + 1)) {
                    goodsReferences.push(index + 1);
                }
            })
        }

        //When at least one item has no suporting document, then we should add the existing supporting documents to the GoodsShipmentForT2LT2LF.SupportingDocuments array
        const hasNoSupportingDocs = GoodsItemsForT2LT2LF.some(item => item.SupportingDocuments.length === 0);

        console.log("fn createSubmitFormService POUS hasNoSupportingDocs", hasNoSupportingDocs)


        if (hasNoSupportingDocs) {
            // Ensure GoodsShipmentForT2LT2LF.SupportingDocuments contains all unique supporting documents from GoodsItemsForT2LT2LF
            GoodsItemsForT2LT2LF.forEach(item => {
                item.SupportingDocuments.forEach(doc => {
                    if (!GoodsShipmentForT2LT2LF.SupportingDocuments.some(existingDoc => existingDoc.ReferenceNumber === doc.ReferenceNumber)) {
                        GoodsShipmentForT2LT2LF.SupportingDocuments.push(doc);
                    }
                });
            });

        }





        return submitForm;
    }

    setModifyRefNumber(submitForm) {
        const mrn = submitForm.HEAHEA.DocNumHEA5;

        const modifyHeahea = submitForm.HEAHEA;

        modifyHeahea.RefNumHEA4 = mrn;
        delete modifyHeahea.DocNumHEA5;


        return submitForm;
    }

    deleteDates(submitForm) {

        delete submitForm.HEAHEA.AccDatHEA158;
        delete submitForm.HEAHEA.AmeAccDatHEA602;

        return submitForm;
    }
    setAmendDatePlace(submitForm) {
        console.log("fn createSubmitFormService setAmendDatePlace")

        const modifyHeahea = submitForm.HEAHEA;

        const declDate = modifyHeahea.DecDatHEA383;
        const declPlace = modifyHeahea.DecPlaHEA394;
        const declPlaceLNG = modifyHeahea.DecPlaHEA394LNG;

        modifyHeahea.AmdDatHEA599 = declDate;
        modifyHeahea.AmdPlaHEA598 = declPlace;
        modifyHeahea.AmdPlaHEA598LNG = declPlaceLNG;
        delete modifyHeahea.DecDatHEA383;
        delete modifyHeahea.DecPlaHEA394;
        delete modifyHeahea.DecPlaHEA394LNG;

        return submitForm;
    }


    setPaymentMethod(submitForm) {

        const gooIteGds = submitForm.GOOITEGDS
        const payMethodArray = [];

        gooIteGds.forEach(item => {
            const caltax = item.CALTAXGOD

            caltax.forEach(tax => {
                payMethodArray.push(tax.MetOfPayCTX1)
                if (!tax.TaxBasCTX1) {
                    tax.TaxBasCTX1 = '0';
                }
                if (!tax.RatOfTaxCTX1) {
                    tax.RatOfTaxCTX1 = '0';
                }
            })

        })

        const uniquePayMethod = [...new Set(payMethodArray)];

        submitForm.HEAHEA.MetOfPayHEA590 = uniquePayMethod[0]

        return submitForm;

    }

    fixDelter(submitForm) {
        console.log("fn createSubmitFormService fixDelter")

        const delter = submitForm.DELTER;
        if (delter.IncCodTDL1 && !delter.ComInfDELTER387) {
            delter.ComInfDELTER387 = '-';
            delter.ComInfDELTER387LNG = 'EL';
        }

        return submitForm;
    }

    setLrnAsMrn(submitForm) {

        const mrn = submitForm._PRIVATE.mrn;

        if (submitForm.HEAHEA.RefNumHEA4) {

            submitForm.HEAHEA.RefNumHEA4 = mrn
        }

        return submitForm;
    }


}
