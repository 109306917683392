<div class="px-8" fxLayout="row" fxLayoutAlign="center center" >
    <div class="led" [matTooltip]="tooltipNetwork$ | async" matTooltipClass="tooltip">
        <img *ngIf="networkOnline$ | async" src="assets/images/general/led-green.png" width="12" height="12" alt="" />
        <img *ngIf="!(networkOnline$ | async)" src="assets/images/general/led-red.png" width="12" height="12" alt="" />
    </div>
    <div class="led" [matTooltip]="tooltipServer$ | async" matTooltipClass="tooltip">
        <img *ngIf="(serverOnline$ | async) && (networkOnline$ | async)" src="assets/images/general/led-green.png"
            width="12" height="12" alt="" />

        <img *ngIf="!(serverOnline$ | async) || !(networkOnline$ | async)" src="assets/images/general/led-red.png"
            width="12" height="12" alt="" />
    </div>
    <div class="led" [matTooltip]="tooltipIcisNet$ | async" matTooltipClass="tooltip">
        <img *ngIf="(icisOnline$ | async) && (serverOnline$ | async) && (isLoginInProgress$ | async)===false && (icisUserLoggedIn$ | async)"
            src="assets/images/general/led-green.png" width="12" height="12" alt="" />

        <img *ngIf="(icisOnline$ | async) && (serverOnline$ | async) && (isLoginInProgress$ | async)"
            [class.blink]="(isLoginInProgress$ | async)" src="assets/images/general/led-orange.png" width="12"
            height="12" alt="" />

        <img *ngIf="(icisOnline$ | async) && (serverOnline$ | async) && (icisUserLoggedIn$ | async)===false && (isLoginInProgress$ | async)===false"
            src="assets/images/general/led-orange.png" width="12"
            height="12" alt="" />


        <img *ngIf="!(icisOnline$ | async) && (serverOnline$ | async)" src="assets/images/general/led-red.png"
            width="12" height="12" alt="" />

        <img *ngIf="!(serverOnline$ | async)" src="assets/images/general/led-orange.png" width="12" height="12"
            alt="" />
    </div>
</div>